<template>
  <v-container fluid class="pa-6">
    <template v-if="loading">
      <loading :text="$t('dashboard.loading')" />
    </template>
    <template v-else>
      <status-io-message :userLoggedIn="true" />
      <v-row>
        <v-col cols="12" :sm="activeApisCount > 0 ? 4 : 12">
          <div v-if="apiStatus" class="user-info-card">
            <h2>{{ $t('dashboard.operators') }}</h2>
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr v-for="api in apiStatus.operatorStatuses" :key="api.name">
                    <td class="pa-0">
                      <v-img max-height="30" max-width="30" contain :src="operatorLogo(api.name)" />
                    </td>
                    <td>
                      <span class="capitalize-first-letter"> {{ api.name }}</span>
                    </td>
                    <td v-if="!api.enabled" />
                    <td v-else-if="api.useMock">
                      {{ $t('dashboard.mock') }}
                    </td>
                    <td v-else />
                    <td>
                      <v-badge v-if="api.enabled" color="green" offset-x="0" offset-y="5" />
                      <v-badge
                        v-else
                        color="#808080"
                        icon="fal fa-lock-alt"
                        offset-x="0"
                        offset-y="5"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
        <v-col v-if="activeApisCount > 0" cols="12" sm="4">
          <div class="user-info-card text-center">
            <template v-if="ongoingAlerts && ongoingAlerts.length > 0">
              <h2>{{ $t('dashboard.ongoingAlerts') }} ({{ ongoingAlerts.length }})</h2>
              <v-simple-table dense>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t('dashboard.alertName') }}
                      </th>
                      <th class="text-right" />
                      <th class="text-right">
                        {{ $t('dashboard.closes') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in ongoingAlerts.slice(0, 3)"
                      :key="item.id"
                      class="pointer"
                      @click="goToAlertDetails(item.id)"
                    >
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-center">
                        <div v-if="item.submissionMode === 1" class="statusBadge statusLive">
                          {{ $t('dashboard.live') }}
                        </div>
                        <div v-else class="statusBadge statusSimulate">
                          {{ $t('dashboard.simulated') }}
                        </div>
                      </td>
                      <td class="text-right">
                        {{ $d(new Date(item.end), 'longDateWithoutYear') }}
                        {{ $t('timePrefix') }}
                        {{ $d(new Date(item.end), 'time') }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            <template v-else>
              <h2>{{ $t('dashboard.noOngoingAlerts') }}</h2>
              <div class="mt-8">
                <i class="fal fa-bullhorn empty-icon" />
              </div>
            </template>
          </div>
        </v-col>
        <v-col v-if="activeApisCount > 0" cols="12" sm="4">
          <div class="user-info-card text-center">
            <template v-if="plannedAlerts && plannedAlerts.length > 0">
              <h2>{{ $t('dashboard.plannedAlerts') }} ({{ plannedAlerts.length }})</h2>
              <v-simple-table dense>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t('dashboard.alertName') }}
                      </th>
                      <th class="text-right" />
                      <th class="text-right">
                        {{ $t('dashboard.starts') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in plannedAlerts"
                      :key="item.id"
                      class="pointer"
                      @click="goToAlertDetails(item.id)"
                    >
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-center">
                        <div v-if="item.submissionMode === 1" class="statusBadge statusLive">
                          {{ $t('dashboard.live') }}
                        </div>
                        <div v-else class="statusBadge statusSimulate">
                          {{ $t('dashboard.simulated') }}
                        </div>
                      </td>
                      <td class="text-right">
                        {{ $d(new Date(item.start), 'longDateWithoutYear') }}
                        {{ $t('timePrefix') }}
                        {{ $d(new Date(item.start), 'time') }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            <template v-else>
              <h2>{{ $t('dashboard.noPlannedAlerts') }}</h2>
              <div class="mt-8">
                <i class="fal fa-alarm-clock empty-icon" />
              </div>
            </template>
          </div>
        </v-col>
      </v-row>
      <template v-if="activeApisCount <= 0">
        <v-alert class="my-4" prominent type="error">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('dashboard.noOperatorsAvailable') }}
            </v-col>
            <v-col class="shrink">
              <v-btn right text @click="getDashData">{{ $t('dashboard.tryAgain') }} </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </template>
      <template v-else>
        <!-- <h3 class="pt-6">{{ $t ('dashboard.shortcuts') }}</h3> -->
        <v-row align="center" justify="space-around">
          <v-col cols="12" sm="6" lg="6">
            <router-link :to="{ name: 'NewAlert' }">
              <div class="selectable-card">
                <span class="fa-stack fa-3x mb-4">
                  <i class="fas fa-circle fa-stack-2x" />
                  <i class="fal fa-bullhorn fa-stack-1x dash-icon" />
                </span>
                <h3>{{ $t('newAlert') }}</h3>
                <p>{{ $t('newAlertDescription') }}</p>
              </div>
            </router-link>
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <router-link :to="{ name: 'AlertLog' }">
              <div class="selectable-card">
                <span class="fa-stack fa-3x mb-4">
                  <i class="fas fa-circle fa-stack-2x" />
                  <i class="fal fa-clipboard-check fa-stack-1x dash-icon" />
                </span>
                <h3>{{ $t('alertsLog') }}</h3>
                <p>{{ $t('alertsLogDescription') }}</p>
              </div>
            </router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4">
            <router-link :to="{ name: 'Areas' }">
              <div class="selectable-card sub-item">
                <span class="fa-stack fa-3x mb-4">
                  <i class="fas fa-circle fa-stack-2x dash-circle" />
                  <i class="fal fa-draw-polygon fa-stack-1x dash-icon" />
                </span>
                <h3>{{ $t('areasMenuItem') }}</h3>
              </div>
            </router-link>
          </v-col>
          <v-col cols="12" lg="4">
            <router-link :to="{ name: 'SubscriberLists' }">
              <div class="selectable-card sub-item">
                <span class="fa-stack fa-3x mb-4">
                  <i class="fas fa-circle fa-stack-2x dash-circle" />
                  <i class="fal fa-address-book fa-stack-1x dash-icon" />
                </span>
                <h3>{{ $t('subscriberLists') }}</h3>
              </div>
            </router-link>
          </v-col>
          <v-col cols="12" lg="4">
            <router-link :to="{ name: 'TextTemplates' }">
              <div class="selectable-card sub-item">
                <span class="fa-stack fa-3x mb-4">
                  <i class="fas fa-circle fa-stack-2x dash-circle" />
                  <i class="fal fa-file-alt fa-stack-1x dash-icon" />
                </span>
                <h3>{{ $t('textTemplates.heading') }}</h3>
              </div>
            </router-link>
          </v-col>
        </v-row>
        <!-- <h3 class="pt-6">{{ $t ('dashboard.scenarios') }}</h3>
        <v-row
          align="center"
          justify="space-around"
        >
          <v-col
            v-for="n in 4"
            :key="n"
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="selectable-card scenario">
              <span class="fa-stack fa-3x mb-4">
                <i class="fas fa-circle fa-stack-2x dash-circle" />
                <i class="fal fa-cogs fa-stack-1x dash-icon" />
              </span>
              <h3>Eksempel scenario {{ n }}</h3>
              <p>Scenario beskrivelse {{ n }}</p>
            </div>
          </v-col>
          <v-row />
        </v-row> -->
      </template>
    </template>
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue';
import StatusIoMessage from '@/components/StatusIoMessage.vue';
import appSettings from '@/appSettings';
export default {
  name: 'Dashboard',
  components: {
    Loading,
    StatusIoMessage,
  },
  data() {
    return {
      loading: true,
      countupDelay: 0,
      countupOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ' ',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
    };
  },
  computed: {
    profile() {
      return this.$store.state.user.profile;
    },
    ongoingAlerts() {
      return this.$store.state.alertLog.ongoingAlerts;
    },
    plannedAlerts() {
      return this.$store.state.alertLog.plannedAlerts;
    },
    apiStatus() {
      return this.$store.state.user.apiStatus;
    },

    activeApisCount() {
      let count = 0;
      if (this.apiStatus?.operatorStatuses) {
        this.apiStatus.operatorStatuses.forEach((api) => {
          if (api.enabled) count++;
        });
      }
      return count;
    },
    isDevelopment() {
      return appSettings.environment === 'local' || appSettings.environment === 'beta';
    },
  },
  mounted() {
    this.getDashData();
  },
  methods: {
    async getDashData() {
      try {
        this.loading = true;
        await this.$store.dispatch('user/getApiStatus');
        await this.$store.dispatch('alertLog/getOngoingAlerts');
        await this.$store.dispatch('alertLog/getPlannedAlerts');
        await this.$store.dispatch('textTemplate/getCountries');
        this.loading = false;
      } catch (error) {
        this.loading = false;
        error.hasBeenHandled = true;
        if (!error.hasBeenHandled) {
          alert(error);
        }
      }
    },
    operatorLogo: function (operator) {
      if (operator === 'telenor') return require(`@/assets/images/telenor.png`);
      if (operator === 'telia') return require(`@/assets/images/telia.png`);
      if (operator === 'ICE') return require(`@/assets/images/ice.svg`);

      return '';
    },
    goToAlertDetails(alertId) {
      this.$router.push({
        name: 'AlertDetails',
        params: { alertId: alertId.toString() },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #457b9d;
}
.selectable-card {
  cursor: pointer;
  padding: 16px;
  background-color: #1d3e52; // white;
  border-radius: 8px;
  min-height: 236px;
  box-shadow: 0px 4px 8px #88888861;
  color: #bbd9ec;
  transition: all 0.2s; /* Animation */
  h3 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  a {
    text-decoration: none;
    color: #bbd9ec !important; //#457B9D;
  }
  &.scenario {
    background-color: #607685;
    color: #fbfdff;
    .dash-circle {
      color: #1d3e52;
    }
    .dash-icon {
      color: #c7d0d6; //#b0d8f1;
    }
  }
  &.sub-item {
    background-color: #607685;
    color: #e9eaeb;
    padding-top: 45px;
    .dash-circle {
      color: #1d3e52;
    }
    .dash-icon {
      color: #c7d0d6; //#b0d8f1;
    }
  }
}
.empty-icon {
  color: #b2bfc7; //#b0d8f1;
  font-size: 4em;
}
.dash-icon {
  color: #1d3e52; //#b0d8f1;
}
.selectable-card:hover {
  transform: scale(1.03) translate(0px, 4px);
  box-shadow: 0px 14px 18px #88888861;
  //  background-color: #eef5f9;
  //  border: 1px solid #c4dae8;
}
.fa-stack {
  display: block;
  margin: auto;
}

.user-info-card {
  padding: 16px;
  background-color: #dbe6e8; // #e6d9bd;
  color: #457b9d;
  border-radius: 8px;
  box-shadow: 0px 4px 8px #88888861;
  text-align: left;
  min-height: 220px;
  //  background: url('../assets/images/bgr2.jpg')  no-repeat center bottom;
  a {
    text-decoration: none;
    color: #b6d9ef;
  }
  p {
    margin-bottom: 0;
  }
}
.theme--light.v-data-table ::v-deep {
  background-color: transparent;
  color: #325971;
  // td {
  //   padding:  0 16px 0 0px !important;
  // }
}
.counter {
  font-size: 3em;
  text-align: center;
  font-weight: 600;
}
.statusBadge {
  padding: 0 4px;
  text-align: center;
  border-radius: 15px;
  max-width: 100px;
  margin: auto;
  &.statusLive {
    background-color: #f7cccc;
    border: 1px solid #ef9a9a;
    color: #670303;
  }

  &.statusSimulate {
    background-color: #ffdfb8;
    border: 1px solid #ffc175;
    color: #703e00;
  }
}
</style>
